/* eslint-disable no-restricted-syntax */
import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { NetworkRequestFailedError } from "@reservauto/react-shared/services/ServiceBase";
import languageStore from "@reservauto/react-shared/stores/languageStore";
import renewingSessionStore from "@reservauto/react-shared/stores/renewingSessionStore";
import {
  add as addDate,
  differenceInSeconds,
  minutesToMilliseconds,
} from "date-fns";
import {
  Log,
  SignoutResponse,
  User,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";
import appUrlParams from "../appUrlParams";
import {
  expireLegacySession,
  loginToLegacy,
  logoutOfLegacy,
} from "../areas/legacy/LegacyAuthenticationService";
import { isLegacyRoute } from "../areas/legacy/legacyRoute";
import appSettings from "../shared/appSettings";
import envSettings from "../shared/envSettings";
import branchStore from "../shared/stores/branchStore";

export interface SignoutState {
  branchId: number;
  languageCode: string;
}

interface RenewSessionTries {
  count: 0;
  lastRenewTime?: Date;
}

const lastLoginTimeStorageKey: StorageKeyInfo<number | null> = {
  key: "Auth_LastLoginTime",
  pathIndependant: true,
  userIndependant: true,
};
const lastNewRefreshTokenTimeStorageKey: StorageKeyInfo<number | null> = {
  key: "Auth_LastNewRefreshTokenTime",
  pathIndependant: true,
  userIndependant: true,
};
const lastRefreshTokenStorageKey: StorageKeyInfo<string> = {
  key: "Auth_LastRefreshToken",
  pathIndependant: true,
  userIndependant: true,
};

const minutesBeforeExpirationToRenew = 15;
const minutesBeforeRefreshTokenExpirationToWarn = 15;
const refreshTokenLifetimeAbsoluteHours = 12;
const refreshTokenLifetimeSlidingHours = 2;

class AuthenticationService {
  private isSigningOut = false;
  private lastAccessToken: string | null = null;
  private refreshTokenAboutToExpireCallbacks: (() => void)[] = [];
  private refreshTokenAbsoluteExpirationDate: Date | null = null;
  private refreshTokenExpirationTimeout = 0;
  private refreshTokenExpiredCallbacks: (() => void)[] = [];
  private refreshTokenSlidingExpirationDate: Date | null = null;
  private renewSessionTries: RenewSessionTries = { count: 0 };
  private sessionExpiredCallbacks: (() => void)[] = [];
  private signedOutCallbacks: ((forceLoginPrompt: boolean) => void)[] = [];
  private userLoadedCallbacks: ((user: User) => void)[] = [];
  private readonly userManager: UserManager | null = null;

  constructor() {
    if (appUrlParams.isMobileAppWebView || appUrlParams.isPublicIframe) {
      return;
    }

    const currentUrlOrigin = window.location.origin;
    this.userManager = new UserManager({
      authority: envSettings.useNewSecurityService
        ? appSettings.ReservautoFrontOfficeIdentityProviderUri
        : appSettings.CommunautoIdSrv,
      automaticSilentRenew: false,
      client_id: envSettings.useNewSecurityService
        ? "CustomerSpaceClient"
        : "CustomerSpaceV2Client",
      loadUserInfo: false,
      post_logout_redirect_uri: `${currentUrlOrigin}/signout-callback`,
      redirect_uri:
        `${currentUrlOrigin}/signin-callback` +
        (envSettings.useNewSecurityService
          ? "?branchId=" + (appUrlParams.branchId ?? 1)
          : ""),
      response_mode: "query",
      response_type: "code",
      scope: envSettings.useNewSecurityService
        ? "openid profile reservautofrontofficerestapi offline_access"
        : "openid profile reservautofrontofficerestapi communautorestapi offline_access",
      silent_redirect_uri: `${currentUrlOrigin}/silent-callback`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.updateFromStorage();
      }
    });
    window.addEventListener("storage", () => this.updateFromStorage());

    this.userManager.events.addUserLoaded((user) => {
      const lastRefreshToken = LocalStorageUtil.get(
        lastRefreshTokenStorageKey,
        null,
      );

      if (lastRefreshToken !== user.refresh_token) {
        LocalStorageUtil.set(
          lastNewRefreshTokenTimeStorageKey,
          new Date().getTime(),
        );
        this.updateRefreshTokenDates();
        LocalStorageUtil.set(lastRefreshTokenStorageKey, user.refresh_token);
      }
    });

    this.userManager.events.addAccessTokenExpired(() => {
      if (!this.isSigningOut) {
        for (const callback of this.sessionExpiredCallbacks) {
          callback();
        }
      }
    });

    if (window.console) {
      Log.setLogger(window.console);
    }
    Log.setLevel(appSettings.Env === "Local" ? Log.INFO : Log.ERROR);

    this.updateRefreshTokenDates();
  }

  public completeSignin(): void {
    expireLegacySession();
  }

  public async getUser(): Promise<User | null> {
    if (!this.userManager) {
      return null;
    }

    const user = await this.userManager.getUser();
    this.lastAccessToken = user?.access_token ?? null;
    return user;
  }

  public off(event: "userLoaded", callback: (user: User) => void): void;
  public off(
    event: "signedOut",
    callback: (forceLoginPrompt: boolean) => void,
  ): void;
  public off(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired",
    callback: () => void,
  ): void;
  public off(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired"
      | "signedOut"
      | "userLoaded",
    callback:
      | (() => void)
      | ((user: User) => void)
      | ((forceLoginPrompt: boolean) => void),
  ): void {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    switch (event) {
      case "refreshTokenAboutToExpire":
        this.refreshTokenAboutToExpireCallbacks =
          this.refreshTokenAboutToExpireCallbacks.filter((c) => c !== callback);
        break;
      case "refreshTokenExpired":
        this.refreshTokenExpiredCallbacks =
          this.refreshTokenExpiredCallbacks.filter((c) => c !== callback);
        break;
      case "sessionExpired":
        this.sessionExpiredCallbacks = this.sessionExpiredCallbacks.filter(
          (c) => c !== callback,
        );
        break;
      case "signedOut":
        this.signedOutCallbacks = this.signedOutCallbacks.filter(
          (c) => c !== callback,
        );
        break;
      case "userLoaded":
        this.userLoadedCallbacks = this.userLoadedCallbacks.filter(
          (c) => c !== callback,
        );
        break;
      default:
        throw new Error(`Unknown event ${event}`);
    }
  }

  public on(event: "userLoaded", callback: (user: User) => void): void;
  public on(
    event: "signedOut",
    callback: (forceLoginPrompt: boolean) => void,
  ): void;
  public on(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired",
    callback: () => void,
  ): void;
  public on(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired"
      | "signedOut"
      | "userLoaded",
    callback:
      | (() => void)
      | ((user: User) => void)
      | ((forceLoginPrompt: boolean) => void),
  ): void {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    switch (event) {
      case "refreshTokenAboutToExpire":
        this.refreshTokenAboutToExpireCallbacks.push(callback as () => void);
        break;
      case "refreshTokenExpired":
        this.refreshTokenExpiredCallbacks.push(callback as () => void);
        break;
      case "sessionExpired":
        this.sessionExpiredCallbacks.push(callback as () => void);
        break;
      case "signedOut":
        this.signedOutCallbacks.push(
          callback as (forceLoginPrompt: boolean) => void,
        );
        break;
      case "userLoaded":
        this.userLoadedCallbacks.push(callback as (user: User) => void);
        break;
      default:
        throw new Error(`Unknown event ${event}`);
    }
  }

  public async renewSession(): Promise<void> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    if (
      this.renewSessionTries.lastRenewTime &&
      differenceInSeconds(new Date(), this.renewSessionTries.lastRenewTime) < 30
    ) {
      this.renewSessionTries.count++;
      if (this.renewSessionTries.count > 4) {
        Logging.warning("Possible renew session loop");
        for (const callback of this.signedOutCallbacks) {
          callback(false);
        }
        return;
      }
    } else {
      this.renewSessionTries.count = 0;
    }

    this.renewSessionTries.lastRenewTime = new Date();
    renewingSessionStore.set(true);
    try {
      let user: User | null;
      try {
        user = await this.retryRequestOnError(() =>
          this.userManager!.signinSilent(this.getRedirectParams()),
        );
      } catch (ex) {
        throw this.convertUserManagerException(ex);
      }

      if (!user) {
        throw new Error("signinSilent failed");
      } else if (user.access_token !== this.lastAccessToken) {
        for (const callback of this.userLoadedCallbacks) {
          callback(user);
        }
        this.lastAccessToken = user.access_token;
      }

      if (isLegacyRoute()) {
        await loginToLegacy(true);
      } else {
        expireLegacySession();
      }
    } finally {
      renewingSessionStore.set(false);
    }
  }

  public shouldRenewSession(user: User): boolean {
    const seconds = minutesBeforeExpirationToRenew * 60;
    return user.expires_in === undefined || user.expires_in < seconds;
  }

  public async signinCallback(): Promise<User> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    let user: User;
    try {
      user = await this.retryRequestOnError(() =>
        this.userManager!.signinRedirectCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }

    this.userManager.clearStaleState();

    // Fix for Identity Server returning multiple values in auth_time
    if (Array.isArray(user.profile.auth_time)) {
      user.profile.auth_time = user.profile.auth_time[0];
      this.userManager.storeUser(user);
    }

    if (
      !this.refreshTokenAbsoluteExpirationDate ||
      this.refreshTokenAbsoluteExpirationDate < new Date() ||
      (this.refreshTokenSlidingExpirationDate &&
        this.refreshTokenSlidingExpirationDate < new Date())
    ) {
      LocalStorageUtil.set(lastLoginTimeStorageKey, new Date().getTime());
      this.updateRefreshTokenDates();
    }

    return user;
  }

  public async signinRedirect(
    branchId?: number,
    localeId?: string,
    url?: string,
    forceLoginPrompt?: boolean,
  ): Promise<void> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    this.userManager.removeUser();
    if (forceLoginPrompt) {
      LocalStorageUtil.remove(lastLoginTimeStorageKey);
    }

    const params = this.getRedirectParams(branchId, localeId, url);
    if (forceLoginPrompt) {
      params["prompt"] = "login";
    }

    try {
      await this.retryRequestOnError(() =>
        this.userManager!.signinRedirect(params),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async signout(): Promise<void> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    this.isSigningOut = true;
    try {
      const user = await this.getUser();

      if (user !== null) {
        try {
          await logoutOfLegacy();
        } catch (ex) {
          Logging.warning(ex);
        }

        LocalStorageUtil.remove(lastLoginTimeStorageKey);

        const state: SignoutState = {
          branchId: branchStore.get().id,
          languageCode: languageStore.get().twoLetterCode,
        };

        try {
          if (envSettings.useNewSecurityService) {
            this.userManager!.removeUser();
          }

          await this.retryRequestOnError(() =>
            this.userManager!.signoutRedirect(
              this.getRedirectParams(
                undefined,
                undefined,
                JSON.stringify(state),
              ),
            ),
          );
        } catch (ex) {
          throw this.convertUserManagerException(ex);
        }
      } else {
        Logging.warning("Could not signout because user was null");
        await this.signinRedirect();
      }
    } finally {
      this.isSigningOut = false;
    }
  }

  public async signoutCallback(): Promise<SignoutResponse> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    try {
      return await this.retryRequestOnError(() =>
        this.userManager!.signoutRedirectCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async silentCallback(): Promise<void> {
    if (!this.userManager) {
      throw new Error("userManager not initialized");
    }

    try {
      await this.retryRequestOnError(() =>
        this.userManager!.signinSilentCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  private convertUserManagerException(exception: unknown): unknown {
    const fetchErrors = ["Failed to fetch", "Load failed", "NetworkError"];
    if (
      exception instanceof Error &&
      fetchErrors.some((e) => exception.message?.startsWith(e))
    ) {
      return new NetworkRequestFailedError();
    }

    return exception;
  }

  private getRedirectParams(
    branchId?: number,
    localeId?: string,
    state?: string,
  ): Record<string, unknown> {
    const branch = branchId ?? branchStore.get().id;
    const locale = localeId ?? languageStore.get().localeId;
    return {
      acr_values: `tenant:${branch}`,
      extraQueryParams: { branch_id: branch, ui_locales: locale },
      state: state ?? window.location.pathname + window.location.search,
      ui_locales: locale,
    };
  }

  private async retryRequestOnError<T>(request: () => Promise<T>): Promise<T> {
    const retryDelaySeconds = 5;
    try {
      return await request();
    } catch {
      return new Promise<T>((resolve, reject) => {
        window.setTimeout(async () => {
          try {
            resolve(await request());
          } catch (error) {
            reject(error);
          }
        }, retryDelaySeconds * 1000);
      });
    }
  }

  private setRefreshTokenExpirationTimeout(): void {
    window.clearTimeout(this.refreshTokenExpirationTimeout);

    if (
      !this.refreshTokenSlidingExpirationDate ||
      !this.refreshTokenAbsoluteExpirationDate
    ) {
      return;
    }

    const refreshTokenExpirationTime = Math.min(
      this.refreshTokenSlidingExpirationDate.getTime(),
      this.refreshTokenAbsoluteExpirationDate.getTime(),
    );
    const timeoutMilliseconds =
      refreshTokenExpirationTime - new Date().getTime();
    const millisecondsBeforeToWarn = minutesToMilliseconds(
      minutesBeforeRefreshTokenExpirationToWarn,
    );

    if (timeoutMilliseconds <= 0) {
      for (const callback of this.refreshTokenExpiredCallbacks) {
        callback();
      }
    } else if (timeoutMilliseconds > millisecondsBeforeToWarn) {
      this.refreshTokenExpirationTimeout = window.setTimeout(() => {
        for (const callback of this.refreshTokenAboutToExpireCallbacks) {
          callback();
        }
        this.setRefreshTokenExpirationTimeout();
      }, timeoutMilliseconds - millisecondsBeforeToWarn);
    } else {
      this.refreshTokenExpirationTimeout = window.setTimeout(() => {
        for (const callback of this.refreshTokenExpiredCallbacks) {
          callback();
        }
      }, timeoutMilliseconds);
    }
  }

  private async updateFromStorage(): Promise<void> {
    if (!this.userManager) {
      return;
    }

    const user = await this.userManager.getUser();
    if (!user) {
      if (this.lastAccessToken !== null) {
        this.userManager.removeUser(); // Unbind events

        for (const callback of this.signedOutCallbacks) {
          callback(true);
        }
        this.lastAccessToken = null;
      }
    } else {
      if (user.access_token !== this.lastAccessToken) {
        for (const callback of this.userLoadedCallbacks) {
          callback(user);
        }
        this.lastAccessToken = user.access_token;
      }
    }

    this.updateRefreshTokenDates();
  }

  private updateRefreshTokenDates(): void {
    const lastLoginTime = LocalStorageUtil.get(lastLoginTimeStorageKey, null);
    const lastLoginDate = lastLoginTime ? new Date(lastLoginTime) : undefined;
    if (lastLoginDate) {
      this.refreshTokenAbsoluteExpirationDate = addDate(lastLoginDate, {
        hours: refreshTokenLifetimeAbsoluteHours,
      });
    } else {
      this.refreshTokenAbsoluteExpirationDate = null;
    }

    const lastNewRefreshTokenTime = LocalStorageUtil.get(
      lastNewRefreshTokenTimeStorageKey,
      null,
    );
    const lastNewRefreshTokenDate = lastNewRefreshTokenTime
      ? new Date(lastNewRefreshTokenTime)
      : undefined;

    if (lastNewRefreshTokenDate) {
      this.refreshTokenSlidingExpirationDate = addDate(
        lastNewRefreshTokenDate,
        { hours: refreshTokenLifetimeSlidingHours },
      );
    } else {
      this.refreshTokenSlidingExpirationDate = null;
    }

    this.setRefreshTokenExpirationTimeout();
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
