import languageStore from "@reservauto/react-shared/stores/languageStore";
import processingStore from "@reservauto/react-shared/stores/processingStore";
import appUrlParams from "../appUrlParams";
import appSettings from "./appSettings";
import branchStore from "./stores/branchStore";

interface IdentityProviderData {
  BranchId: number;
  IsMobileApp: boolean;
  LanguageCode: string;
  PageData: { ShowSubscriptionLink?: boolean };
  ReactScriptSourceUrl: string;
  ReactUserOriginUrl: string;
}

declare global {
  interface Window {
    reservautoIdentityProviderData?: IdentityProviderData;
  }
}

export function createIdentityProviderLink(url: string): string {
  const urlParams = new URLSearchParams();
  urlParams.append("branch", branchStore.get().id.toString());
  urlParams.append("isMobileApp", appUrlParams.isMobileAppWebView.toString());
  urlParams.append("ui_locales", languageStore.get().localeId);

  return `${appSettings.ReservautoFrontOfficeIdentityProviderUri}${url}?${urlParams}`;
}

const identityProviderData = window.reservautoIdentityProviderData;
export default identityProviderData
  ? {
      ...identityProviderData,

      setValue: (key: string, value: string): void => {
        const input = window.document.querySelector(
          `input[name='${key.toString()}']`,
        )! as HTMLInputElement;
        input.value = value;
      },
      submitForm: (): void => {
        processingStore.pageLoadingStarted();
        const formEle = window.document.querySelector(
          "form",
        ) as HTMLFormElement;
        formEle.submit();
      },
    }
  : undefined;
